.ant-card-cover > img {
    height: 10rem;
    object-fit: cover;
}

.ActionButton {
    z-index: 10;
}

.ActionButtonsWrapper {
    position: absolute;
    background: #00000042;
    width: 100%;
    height: 160px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.Wrapper {
    position: relative;
}

.AppStatus {
    text-align: center;
    color: #fff;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
}

.AppStatusReleased {
    background-color: #52c41a;
}

.AppStatusUnreleased {
    background-color: #f5222d;
}

.AppPresentationCard {
    min-width: 300px;
}

@media (max-width:481px)  {
    .AppPresentationCard {
        min-width: 250px;
    }
}
