@import '~antd/dist/antd.css';

:root {
    --android-color: #30d780;
    --windows-color: #00a8e8;
    --macos-color: #585858;
}

.Logo {
    height: 3rem;
    user-select: none;
    -webkit-user-drag: none;
}

.MenuToggle {
    position: absolute;
    background: #fff;
    z-index: 1;
    transform: translate(50%, -50%);
    right: 0;
    top: 24px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    display: flex !important;
    justify-content: center;
    color: #001529;
}

.MenuItemLikeButton {
    color: rgba(255, 255, 255, 0.65);
    padding-left: 24px;
    cursor: pointer;
    height: 40px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.MenuItemLikeButton:hover {
    color: #fff;
}

.MenuItemLikeButton > span:last-child {
    margin-left: 10px;
    flex: auto;
    opacity: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.MenuItemLikeButton.collapsed > span:last-child {
    opacity: 0;
}

.MenuItemLikeButton.collapsed {
    padding: 0 calc(50% - 16px / 2);
    left: 0;
    margin-left: 0;
    border-right: 0;
    text-overflow: clip;
    white-space: nowrap;
    transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Header {
    background: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Content {
    background: #fff;
    overflow: auto;
}

.App {
    min-height: 100vh;
}

.AppsWrapper {
    --gap: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--gap));
    width: calc(100% + 12px);
}

.AppsWrapper > * {
    margin: var(--gap) 0 0 var(--gap);
    flex: 0 0 calc(20% - 12px);
}
