.ApplicationUpload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 3rem;
}

.ApplicationFileName {
    display: inline-block;
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Tag {
    display: inline-block;
    max-width: 23rem;
    margin-top: 1rem !important;
}

.ant-tag {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
