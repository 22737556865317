.container {
    position: fixed;
    display: flex;
    height: 10rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #fff;
    bottom: 0;
    z-index: 10;
    flex-direction: row;
    top: calc(100% - 10rem);
    padding: 0.5rem 0;
}

.close {
    position: absolute;
    right: 0;
    top: 0;
}

.logo {
    height: 3rem;
    padding: 0.25rem;
}

.actionContainer {
    text-align: center;
    margin-left: 2rem;
}

.text {
    font-size: 1rem;
    font-weight: 300;
}

.download {

}
